/*eslint-disable*/
import http from "../Environment.js";

class ProductReviewService {
    add(email) {
        return http.post(`/product/review/send-otp?email=${email}`);
    }
 
    addForm(formData) {
        return http.post(`/product/review`, formData);
    }

    getReviews(id) {
        return http.get(`/product/${id}/review`);
    }
}

export default new ProductReviewService();