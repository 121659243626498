import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProductService from "../service/ProductService";

export const getAllProduct = createAsyncThunk(
    "getAllProduct",
    async () => {
      try {
        const res = await ProductService.getAll();
        return res.data;
      } catch (error) {
        // console.error("Error fetching all product:", error);
        throw error;
      }
    }
  );

  export const getProductById = createAsyncThunk(
    "getProductById",
    async (productId) => {
      try {
        const res = await ProductService.getById(productId);
        return res.data;
      } catch (error) {
        console.error(`Error fetching product with ID ${productId}:`, error);
        throw error;
      }
    }
  );

  export const getLatestProducts = createAsyncThunk(
    "getLatestProducts",
    async () => {
      try {
        const res = await ProductService.recentProducts();
        return res.data;
      } catch (error) {
        throw error;
      }
    }
  );

  export const getProductByCategoryId = createAsyncThunk(
    "getProductByCategoryId",
    async (categoryId) => {
      try {
        const res = await ProductService.getByCategoryId(categoryId);
        return res.data;
      } catch (error) {
        console.error(`Error fetching category by ID  ${categoryId}:`, error);
        throw error;
      }
    }
  );

  const productSlice = createSlice({
    name: "productt",
    initialState: {
      productList:[],
      recentProducts:[],
      status:'idle',
      error:null,
      selectedProduct: null,
    },
    reducers: {},
  
    extraReducers: (builder) => {
      builder
      .addCase(getAllProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productList = action.payload;
      })
      .addCase(getAllProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      
      .addCase(getProductById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productList = action.payload;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    
      .addCase(getLatestProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLatestProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.recentProducts = action.payload;
      })
      .addCase(getLatestProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(getProductByCategoryId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProductByCategoryId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productList = action.payload; 
      })
      .addCase(getProductByCategoryId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    },
  });
  
  const { reducer } = productSlice;
  export default reducer;