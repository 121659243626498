import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PlaceOrder from "./pages/PlaceOrder";
// import ProductList from "./pages/ProductList";

// home pages
const HomePage = lazy(() => import("./pages/HomePage"));

// shop pages
const ProductList = lazy(() => import("./pages/ProductList"));

// product pages
const SingleProduct = lazy(() => import("./pages/SingleProduct"));

// other pages
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const InternationalEnquiry = lazy(() =>
  import("./pages/InternationalEnquiry")
);
const Cart = lazy(() => import("./pages/Cart"));
const Wishlist = lazy(() => import("./pages/Wishlist"));
const Checkout = lazy(() => import("./pages/Checkout"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path={process.env.PUBLIC_URL + "/"} element={<HomePage />} />

            {/* Homepages */}
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<HomePage />}
            />
            {/* Shop pages */}
            <Route
              path={process.env.PUBLIC_URL + "/products"}
              element={<ProductList />}
            />

            {/* Shop product pages */}
            <Route
              path={process.env.PUBLIC_URL + "/product/:id"}
              element={<SingleProduct />}
            />

            {/* Other pages */}
            <Route
              path={process.env.PUBLIC_URL + "/about"}
              element={<About />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/internatinal-enquiry-form"}
              element={<InternationalEnquiry />}
            />

            <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
            <Route
              path={process.env.PUBLIC_URL + "/wishlist"}
              element={<Wishlist />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              element={<Checkout />}
            />
            <Route
                path={process.env.PUBLIC_URL + "/order-placed"}
                element={<PlaceOrder/>}
              /> 
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
