import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EnquiryFormService from "../service/EnquiryFormService";
import cogoToast from "cogo-toast";

export const addEnquiry = createAsyncThunk(
    "addEnquiry",
    async (formData) => {
        try {
        const res = await EnquiryFormService.add(formData);
        return res.data;
        } catch (error) {
        console.error("Error fetching EnquiryForm:", error);
        throw error;
        }
    }
    );

    const enquiryFormSlice = createSlice({      
        name: "enquiries",
        initialState: {
            enquiry: [],
            status: "idle",
            error: null,
        },
        reducers: {},
        extraReducers: (builder) => {
            builder
            .addCase(addEnquiry.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addEnquiry.fulfilled, (state, action) => {
                state.enquiry.push(action.payload);
                cogoToast.success("Enquiry details submitted successfully", {position: "top-right"});

            })
            .addCase(addEnquiry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
        },
    });

    const { reducer } = enquiryFormSlice;
    export default reducer;