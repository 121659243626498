import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContactFormService from '../service/ContactForm';
import cogoToast from 'cogo-toast';

export const addContactForm = createAsyncThunk(
    'addContactForm',
    async (formData) => {
        try {
        const res = await ContactFormService.add(formData);
        return res.data;
        } catch (error) {
        console.error('Error fetching ContactForm:', error);
        throw error;
        }
    }
    );

    const contactFormSlice = createSlice({
        name: 'contacts',
        initialState: {
            contact: [],
            status: 'idle',
            error: null,
        },
        reducers: {},
        extraReducers: (builder) => {
            builder
            .addCase(addContactForm.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addContactForm.fulfilled, (state, action) => {
                state.contact.push(action.payload);
                cogoToast.success("Contact details submitted successfully", {position: "top-right"});

            })

            .addCase(addContactForm.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                cogoToast.error("Something went wrong", {position: "top-right"});
            });
            // cogoToast.success("Contact details submitted successfully", {position: "top-right"});

        },

    });
    // .addCase(addContactForm.fulfilled, (state, action) => {
    //     state.contact.push(action.payload);
    //     state.successMessage = "Form submitted successfully!";
    // })


    const { reducer } = contactFormSlice;
    export default reducer;