import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubscriptionService from '../service/SubscriptionService';
import cogoToast from 'cogo-toast';

export const addSubscription = createAsyncThunk(
    'addSubscription',
    async (formData) => {
        try {
        const res = await SubscriptionService.add(formData);
        return res.data;
        } catch (error) {
        console.error('Error fetching Subscription:', error);
        throw error;
        }
    }
    );

    const subscriptionSlice = createSlice({
        name: 'subscription',
        initialState: {
            subscriptions: [],
            status: 'idle',
            error: null,
        },
        reducers: {},
        extraReducers: (builder) => {
            builder
            .addCase(addSubscription.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addSubscription.fulfilled, (state, action) => {
                state.subscriptions.push(action.payload);
                cogoToast.success("Subscribed Successfully", {position: "top-right"});

            })

            .addCase(addSubscription.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                cogoToast.error("Already subscribed or something went wrong", {position: "top-right"});
            });

        },

    });

    const { reducer } = subscriptionSlice;
    export default reducer;