/*eslint-disable*/
import http from "../Environment.js";

class ProductService {
    getAll(recentProduct, categoryId) {
      return http.get("/product", {params: {recentProduct, categoryId}});
    }
    
    getProductImg(id) {
      return http.get(`/product/${id}/img`,{responseType: 'arraybuffer'})
    }
  
    getById(id) {
      return http.get(`/product/${id}`);
    }

    getByCategoryId(id) {
      return http.get(`/category/${id}/product`);
    }
}

export default new ProductService();