import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom"; 
import SEO from "../components/seo";

function PlaceOrder() {
    let { pathname } = useLocation();

    return (
      <Fragment>
        <SEO
          titleTemplate="order-placed"
          description="404 of flone react minimalist eCommerce template."
        />
        {/* <LayoutOne> */}
        {/* <LayoutOne headerTop="visible"> */}
          
          {/* <Breadcrumb 
            pages={[
              {label: "Home", path: process.env.PUBLIC_URL + "/" },
              {label: "404 page", path: process.env.PUBLIC_URL + pathname }
            ]} 
          /> */}
          <div className="error-area pt-100 pb-100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-8 text-center">
                  <div className="error">
                    {/* <h1>404</h1> */}
                    <h2>Order Placed Successfully!</h2>
                    <p>
                    Your order has been successfully placed. Thank you for shopping with us!
                    </p>
                    {/* <form className="searchform mb-50">
                      <input
                        type="text"
                        name="search"
                        id="error_search"
                        placeholder="Search..."
                        className="searchform__input"
                      />
                      <button type="submit" className="searchform__submit">
                        <i className="fa fa-search" />
                      </button>
                    </form> */}
                    {/* <div className="cart-clear"> */}
                    {/* </div> */}
                    <Link to={process.env.PUBLIC_URL + "/"} className="error-btn-cus">
                      Continue Shopping
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </LayoutOne> */}
      </Fragment>
    );
}

export default PlaceOrder